/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM712P
 * 화면 설명: 수금체크명세 > 수금체크명세 상세조건 
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.01.26
 * 작 성 자: 서영화 
 */
<template>
  <ur-page-container title="수금체크명세 상세조건" :show-title="true" type="subpage" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-sch-area pt30 pb80">
        <!-- 조직관련 COMPONENT : 관리자 접근시 버튼 노출 -->
        <SearchCondOrgPM ref="refSearchOrg" v-show="pIsMngr" v-model="lv_SelectOrg" :isFofType="1" :pOrgData="pOrgData712P" @selectResult="fn_SelectCnsltResult"/>
        <!-- 고객명 -->
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="">
          <span class="label-title mb10">고객명</span>
          <mo-text-field v-model="searchedCustNm"   class="form-input-name full" underline placeholder="고객명을 입력해주세요" clearable/>
        </ur-box-container>
        <!-- 고객구분 -->
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="mt20">
          <span class="label-title mb10">고객구분</span>
          <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="gContScCd.value" class="chip-type-wrap" solid primary>
              <mo-segment-button v-for="(item, idx) in gContScCds" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <!-- 자원구분 -->
        <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="mt20">
          <span class="label-title mb10">자원구분</span>
          <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="cDResrcScCd.value" class="chip-type-wrap" solid primary>
              <mo-segment-button v-for="(item, idx) in cDResrcScCds" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <!-- 입금상태 -->
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="mt20">
          <span class="label-title mb10">입금상태</span>
          <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="thmmRctmScd.value" class="chip-type-wrap chip-w50" solid primary>
              <mo-segment-button v-for="(item, idx) in thmmRctmScds" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <!-- 가입상품 -->
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="mt20">
          <span class="label-title mb10">가입상품</span>
          <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="salesP.value" class="chip-type-wrap" solid primary>
              <mo-segment-button v-for="(item, idx) in salesPs" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <!-- UL구분 : 2023.04.13 추가 -->
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="mt20">
          <span class="label-title mb10">UL구분</span>
          <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="ulScCd.value" class="chip-type-wrap m-size-chng" solid primary>
              <mo-segment-button v-for="(item, idx) in ulScCds" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <!-- 유지개월 : 2023.04.13 추가 -->        
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="mt20">
          <span class="label-title mb10">유지개월</span>
          <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="ukepNts.value" class="chip-type-wrap" solid primary>
              <mo-segment-button v-for="(item, idx) in ukepNtss" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <!-- 납입방법 -->
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="mt20 pb60">
          <span class="label-title mb10">납입방법</span>
          <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="prmPadMthCd.value" class="chip-type-wrap" solid primary>
              <mo-segment-button v-for="(item, idx) in prmPadMthCds" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <!-- /검색조건 영역 -->

        <!-- 초기화/조회 버튼 -->
        <ur-box-container alignV="start" componentid="ur_box_container_06" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_01" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ClickClear">초기화</mo-button>
            <mo-button componentid="mo_button_02" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ClickSearch">조회</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
import SearchCondOrgPM from '@/ui/pm/SearchCondOrgPM.vue'
import pmConstants     from '@/config/constants/pmConstants.js'

export default {

  name: 'MSPPM712P', 
  screenId: 'MSPPM712P', 
  components: {    
    SearchCondOrgPM
  },//componets,

  props:{
    pPage: String,                // 부모 페이지명 
    pIsMngr: Boolean,             // 지점장여부 
    pOrgData: {                   // 조직 및 컨설턴트 정보 
      type: Object,
      default: {}
    },
    pSearchedCustNm: String,      // 고객명 
    pGContScCd: String,           // 고객구분
    pCDResrcScCd: String,         // 자원구분
    pThmmRctmScd: String,         // 입금상태                     
    pSalesP: String,              // 가입상품
    pUlScCd: String,              // UL구분 
    pUkepNts: String,             // 유지개월 
    pPrmPadMthCd: String,         // 납입방법
    pSelectedItems: {             // 조회조건 
      type: Object,
      default: {}
    }
  },//props

  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  data () {
    return {

      lv_ReturnVal: {},            // 리턴값
      isReturn: false,             // 리턴값 세팅여부 

      /** 
       * 사용자 정보 변수
       */ 
      lv_SelectOrg: {},             // 조직 및 담당 컨설턴트 컴포넌트 데이터 
      pOrgData712P: {},             
      userInfo: {},                 // 사용자정보                
      
      /** 
       * 조회 변수 
       */
      searchedCustNm: '',           // 고객명
      gContScCds: [],               // 고객구분 CODE-LIST
      gContScCd: {},                // 선택된 고객구분 데이터
      cDResrcScCds: [],             // 자원구분 CODE-LIST
      cDResrcScCd: {},              // 선택된 자원구분 데이터
      thmmRctmScds: [],             // 입금상태 CODE-LIST
      thmmRctmScd: {},              // 선택된 입금상태 데이터
      salesPs: [],                  // 가입상품 CODE-LIST
      salesP: {},                   // 선택된 가입상품 데이터
      ulScCds: [],                  // UL구분 CODE-LIST
      ulScCd: {},                   // 선택된 UL구분 데이터 
      ukepNtss: [],                 // 유지개월 CODE-LIST
      ukepNts: {},                  // 선택된 유지개월 데이터 
      prmPadMthCds: [],             // 납입방법 CODE-LIST
      prmPadMthCd: {},              // 선택된 납입방법 데이터

      selectedItems: {},            // 조회조건 결과

      /**
       * 제어변수
       */
      isCleared: false,             // 조회조건 초기화 여부 

    }
  },//data

  created () {
    window.vue.getStore('progress').dispatch('FULL')              // post 호출 전 Progress 타입 설정.
    
    this.userInfo = this.getStore('userInfo').getters.getUserInfo // 사용자 데이터 설정 

    /**
     * props 데이터 및 공통코드 설정
     */
    this.pOrgData712P   = this.pOrgData         
    this.searchedCustNm = this.pSearchedCustNm  
    this.gContScCds     = pmConstants.CLMNY_CHK_SPCF_CUST_SC_CD
    this.cDResrcScCds   = pmConstants.CLMNY_CHK_SPCF_RESRC_SC_CD
    this.thmmRctmScds   = pmConstants.CLMNY_CHK_SPCF_RCTM_STAT_CD
    this.salesPs        = pmConstants.CLMNY_CHK_SPCF_ENT_PRDT_CD
    this.ulScCds        = pmConstants.CLMNY_CHK_SPCF_UL_SC_CD
    this.ukepNtss       = pmConstants.CLMNY_CHK_SPCF_UKEP_MNTH
    this.prmPadMthCds   = pmConstants.CLMNY_CHK_SPCF_PAD_MTH_CD
    
    /**
     * 조회조건 결과 설정 
     */
    if(JSON.stringify(this.pSelectedItems) === '{}'){
      // 초기화 
      // 선택지점, 선택사업부, 선택지역단, 컨설턴트, 고객명, 고객구분, 자원구분, 입금상태, 가입상품, 납입방법
      this.selectedItems = {
        hofOrgCd:       {title: '', text: '', idx: 1},                              
        dofOrgCd:       {title: '', text: '', idx: 2},                              
        fofOrgCd:       {title: '', text: '', idx: 3},                              
        cnsltEno:       {title: '컨설턴트', text: '', idx: 4},                        
        searchedCustNm: {title: '고객', text: '', idx: 5},                           
        gContScCd:      {title: '고객구분', text: '', idx: 6},  
        cDResrcScCd:    {title: '자원구분', text: '', idx: 7}, 
        thmmRctmScd:    {title: '입금상태', text: '', idx: 8}, 
        salesP:         {title: '가입상품', text: '', idx: 9},     
        ulScCd:         {title: 'UL구분', text: '', idx: 10},     
        ukepNts:        {title: '유지개월', text: '', idx: 11},     
        prmPadMthCd:    {title: '납입방법', text: '', idx: 12},
      }
    }else{
      this.selectedItems  = this.pSelectedItems     
    }
    
  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

    // 조회조건 선택값 설정 
    this.gContScCd   = Object.assign({}, this.gContScCds.filter((item) => item.value === this.pGContScCd)[0])
    this.cDResrcScCd = Object.assign({}, this.cDResrcScCds.filter((item) => item.value === this.pCDResrcScCd)[0])
    this.thmmRctmScd = Object.assign({}, this.thmmRctmScds.filter((item) => item.value === this.pThmmRctmScd)[0])
    this.salesP      = Object.assign({}, this.salesPs.filter((item) => item.value === this.pSalesP)[0])
    this.ulScCd      = Object.assign({}, this.ulScCds.filter((item) => item.value === this.pUlScCd)[0])
    this.ukepNts     = Object.assign({}, this.ukepNtss.filter((item) => item.value === this.pUkepNts)[0])
    this.prmPadMthCd = Object.assign({}, this.prmPadMthCds.filter((item) => item.value === this.pPrmPadMthCd)[0])
  
  },//mounted

  watch: {
    
    /**
     * 선택된 고객구분 데이터를 저장한다.
     */
    'gContScCd.value': function(val){
      let data =  this.gContScCds.filter((item) => item.value === val)
      this.gContScCd = Object.assign({}, data[0])
    },
    
    /**
     * 선택된 자원구분 데이터를 저장한다.
     */
    'cDResrcScCd.value': function(val){
      let data =  this.cDResrcScCds.filter((item) => item.value === val)
      this.cDResrcScCd = Object.assign({}, data[0])
    },
    
    /**
     * 선택된 입금상태 데이터를 저장한다.
     */
    'thmmRctmScd.value': function(val){
      let data =  this.thmmRctmScds.filter((item) => item.value === val)
      this.thmmRctmScd = Object.assign({}, data[0])
    },
    
    /**
     * 선택된 가입상품 데이터를 저장한다.
     */
    'salesP.value': function(val){
      let data =  this.salesPs.filter((item) => item.value === val)
      this.salesP = Object.assign({}, data[0])
    },
    
    /**
     * 선택된 UL구분 데이터를 저장한다.
     */
    'ulScCd.value': function(val){
      let data =  this.ulScCds.filter((item) => item.value === val)
      this.ulScCd = Object.assign({}, data[0])
    },
    
    /**
     * 선택된 유지개월 데이터를 저장한다.
     */
    'ukepNts.value': function(val){
      let data =  this.ukepNtss.filter((item) => item.value === val)
      this.ukepNts = Object.assign({}, data[0])
    },
    
    /**
     * 선택된 납입방법 데이터를 저장한다.
     */
    'prmPadMthCd.value': function(val){
      let data =  this.prmPadMthCds.filter((item) => item.value === val)
      this.prmPadMthCd = Object.assign({}, data[0])
    },
  },//watch

  computed: {},//computed

  methods: {

    /**
     * @description 조직 및 담당컨설턴트 변경 시, 전역변수에 세팅해준다.
     * @param       {Object} pData 조직 및 담당컨설턴트 정보 
     */
    fn_SelectCnsltResult(pData){
      this.lv_SelectOrg = pData
    },

    /**
     * @description 선택된 조건값을 초기 조건값으로 초기화 한다.
     */
    fn_ClickClear(){
      
      // 관리자 접근 시 조직정보 초기화 후, 재조회
      if (this.pIsMngr) this.$refs.refSearchOrg.fn_GetFofRol()

      this.pOrgData712P   = {}
      this.searchedCustNm = ''                     
      this.gContScCd      = Object.assign({}, this.gContScCds[1])
      this.cDResrcScCd    = Object.assign({}, this.cDResrcScCds[3])
      this.thmmRctmScd    = Object.assign({}, this.thmmRctmScds[4])
      this.salesP         = Object.assign({}, this.salesPs[1])
      this.ulScCd         = Object.assign({}, this.ulScCds[0])
      this.ukepNts        = Object.assign({}, this.ukepNtss[0])
      this.prmPadMthCd    = Object.assign({}, this.prmPadMthCds[0])

    },
        
    /**
     * @description 부모화면에 전달할 리턴 데이터를 세팅해준다.
     */
    fn_SetReturnVal(){
            
      // 초기화 
      this.lv_ReturnVal = {} 

      // 조회조건 결과 설정
      this.fn_SetSelectedItem()

      // 리턴값 설정
      this.lv_ReturnVal.clmClnsltId = !_.isEmpty(this.lv_SelectOrg.cnslt)  ? this.lv_SelectOrg.cnslt.key  : '' 
      this.lv_ReturnVal.clmDofOrgId = !_.isEmpty(this.lv_SelectOrg.dofOrg) ? this.lv_SelectOrg.dofOrg.key : '' 
      this.lv_ReturnVal.clmFofOrgId = !_.isEmpty(this.lv_SelectOrg.fofOrg) ? this.lv_SelectOrg.fofOrg.key : '' 
      this.lv_ReturnVal.clmHofOrgNo = !_.isEmpty(this.lv_SelectOrg.hofOrg) ? this.lv_SelectOrg.hofOrg.key : '' 
      this.lv_ReturnVal.pOrgData    = this.lv_SelectOrg 

      this.lv_ReturnVal.searchedCustNm = this.searchedCustNm.trim()       
      this.lv_ReturnVal.gContScCd      = this.gContScCd.value             
      this.lv_ReturnVal.cDResrcScCd    = this.cDResrcScCd.value           
      this.lv_ReturnVal.thmmRctmScd    = this.thmmRctmScd.value           
      this.lv_ReturnVal.salesP         = this.salesP.value               
      this.lv_ReturnVal.ulScCd         = this.ulScCd.value               
      this.lv_ReturnVal.ukepNts        = this.ukepNts.value               
      this.lv_ReturnVal.prmPadMthCd    = this.prmPadMthCd.value           
      this.lv_ReturnVal.selectedItems  = this.selectedItems              
      this.lv_ReturnVal.isCleared      = this.isCleared               

      // 리턴값 세팅여부 
      this.isReturn = true

    },

    /**
     * @description 검색결과 데이터(조건이름)을 저장한다.
     *              초기 조건값과 현재 조건값이 동일하지 않을 때만 저장한다.
     */
    fn_SetSelectedItem(){

      if(this.$bizUtil.isEmpty(this.searchedCustNm.trim()) && (this.gContScCd.value === this.gContScCds[1].value) && 
        (this.cDResrcScCd.value === this.cDResrcScCds[3].value) && (this.thmmRctmScd.value === this.thmmRctmScds[4].value) && (this.salesP.value === this.salesPs[1].value) &&
        (this.ulScCd.value === this.ulScCds[0].value) && (this.ukepNts.value === this.ukepNtss[0].value) && (this.prmPadMthCd.value === this.prmPadMthCds[0].value)){
        
        // 조직 컴포넌트 데이터가 존재할 경우
        if(!_.isEmpty(this.lv_SelectOrg)){
          if(this.userInfo.userId === this.lv_SelectOrg.cnslt.key){
            this.isCleared = true 
            return
          }
        }else{
          this.isCleared = true 
          return 
        }

      }
      
      this.isCleared = false 

      this.selectedItems.hofOrgCd.text = !_.isEmpty(this.lv_SelectOrg.hofOrg) ? this.lv_SelectOrg.hofOrg.label : '' 
      this.selectedItems.dofOrgCd.text = !_.isEmpty(this.lv_SelectOrg.dofOrg) ? this.lv_SelectOrg.dofOrg.label : '' 
      this.selectedItems.fofOrgCd.text = !_.isEmpty(this.lv_SelectOrg.fofOrg) ? this.lv_SelectOrg.fofOrg.label : '' 
      this.selectedItems.cnsltEno.text = !_.isEmpty(this.lv_SelectOrg.cnslt)  ? new Object(this.lv_SelectOrg.cnslt).label.split('(')[0].trim() : ''
      
      this.selectedItems.searchedCustNm.text = this.searchedCustNm.trim()
      this.selectedItems.gContScCd.text      = this.gContScCd.text
      this.selectedItems.cDResrcScCd.text    = this.cDResrcScCd.text
      this.selectedItems.thmmRctmScd.text    = this.thmmRctmScd.text
      this.selectedItems.salesP.text         = this.salesP.text
      this.selectedItems.ulScCd.text         = this.ulScCd.text
      this.selectedItems.ukepNts.text        = this.ukepNts.text
      this.selectedItems.prmPadMthCd.text    = this.prmPadMthCd.text

    },

    /**
     * @description 리턴 데이터 세팅 후, 팝업을 닫는다.
     */
    fn_ClickSearch(){

      // 리턴값 세팅 
      this.fn_SetReturnVal()   

      if(this.isReturn){
        this.$emit('searchPopup', { rtnData: this.lv_ReturnVal})
      }
      
    },

 },// methods

}//export default


</script>