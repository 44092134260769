/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM711M
 * 화면 설명: 수금체크명세 
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.01.26
 * 작 성 자: 서영화  
 */

<template>
  <ur-page-container title="수금체크명세" @on-header-left-click="fn_ClickBackBtn" @action-search-click="fn_OpenMSPPM712P"
                      class="msp" :show-title="true" type="subpage" action-type="search" :topButton="true">

    <template #frame-header-fixed >
      <ur-box-container alignV="start"  direction="column" class="msp-pm-wrap">
        <!-- 검색결과 영역 start-->
        <ur-box-container  v-if="isSearched && !isCleared" componentid="ur-box-container_001" alignV="start" direction="column" class="search-result-box bd-T-Ty1 bd-b-Ty1"> 
          <strong class="tit">검색결과</strong>
          <mo-tab-box  default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
            <template v-for="item in searchedItems">
              <mo-tab-label v-if="!$bizUtil.isEmpty(item.text)" @click="fn_OpenMSPPM712P" :idx="item.idx" :key="item.idx" >
                <span class="tit" v-if="!$bizUtil.isEmpty(item.title)">{{ item.title }}</span>
                <span class="txt">{{ item.text }}</span> 
              </mo-tab-label>
            </template>
          </mo-tab-box>
        </ur-box-container>
        <!-- 검색결과 영역 end-->

        <!-- 데이터개수 영역 start -->
        <ur-box-container alignV="start" componentid="ur-box-container_002" direction="column" class="ns-check-sum">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <!-- 전체 데이터 개수  -->
                <span class="sum" v-if="checkedCount <= 0">총 <strong class="crTy-blue3">{{ totalCount }}</strong> 명</span>
                <!-- 선택된 데이터 개수 -->
                <span class="sum" v-else><strong class="crTy-blue3">{{ checkedCount }}</strong> 명 선택</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        <!-- 데이터개수 및 체크박스 영역 end -->

      </ur-box-container>
    </template>

    <!-- Content영역 start -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-list-area msp-list-area pb150">
        <!-- 조회결과 리스트 -->
        <ur-box-container v-if="!isNoData" alignV="start" componentid="ur_box_container_003" direction="column" class="ns-check-list type-2">
          <mo-list :list-data="clmnyChkSpcfSVO">
            <template #list-item="{ index, item }">
              <mo-list-item class="type-itm-2" :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)">
                <!-- 체크박스 -->
                <mo-checkbox v-model="item.checked" @input="fn_ClickCheckPartial" :disabled="isDisabledCheck" :ref="`chk${index}`"/> 
                <div class="list-item__contents">
                  <div class="list-item__contents__title mb10 fexTy3">
                    <!-- 계약자명 -->
                    <span @click.stop="fn_OpenCustInfoBs(item)" class="name">{{ item.policyHoderNm }}</span>
                    <span>
                      <!-- 입금상태 -->
                      <mo-badge :text="item.thmmRctmScCdTt" v-if="!$bizUtil.isEmpty(item.thmmRctmScCdTt)" class="badge-sample-badge lightgreen sm" shape="status">{{ item.thmmRctmScCdTt }}</mo-badge>
                      <!-- 자원구분 -->
                      <mo-badge :text="item.dresrcScCdTt" v-if="!$bizUtil.isEmpty(item.dresrcScCdTt)" class="badge-sample-badge lightblue sm" shape="status">{{ item.dresrcScCdTt }}</mo-badge> 
                    </span>
                  </div>
                  <div class="list-item__contents__title fwn linkTy">
                      <span @click.stop="fn_MoveMSPPM931M(item.applnrTtEnc, item.chnlCustId)" class="tit fs16rem txtSkip">{{ item.productTt }}</span>
                      <span @click.stop="fn_MoveMSPPM931M(item.applnrTtEnc, item.chnlCustId)" class="prd-num fs16rem pl4">({{ item.markUpContNo }})</span>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 구분 -->
                    <span class="fs14rem crTy-bk7 mr10">구분</span><span class="crTy-bk1">{{ item.salesNPLCdTt }}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 최종입금일 -->
                    <span class="fs14rem crTy-bk7 mr10">최종입금일</span><span class="crTy-bk1">{{ item.lstPadDt }}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 유지사항 -->
                    <span class="fs14rem crTy-bk7 mr10">유지사항</span><span class="crTy-bk1">{{ item.ukepMatr + '회' }}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 이체일자 (납입방법)-->
                    <!-- <span class="fs14rem crTy-bk7 mr10">이체일자</span><span class="crTy-bk1">{{ item.atrHopDdCdTt }}({{ item.prmPadMthCdTt + ', ' + item.banka }})</span> -->
                    <span class="fs14rem crTy-bk7 mr10">이체일자</span><span class="crTy-bk1">{{ fn_GetPadInfo(item) }}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 보험료 -->
                    <span class="fs14rem crTy-bk7 mr10">보험료</span><span class="crTy-blue3 fwb">{{ item.premaftertaxAm }}</span>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>

        <!-- NoData 영역 start -->
        <ur-box-container v-else alignV="start" componentid="ur_box_container_008" direction="column" class="ns-contract-list no-data">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span>데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        <!-- NoData 영역 end -->

      </ur-box-container>
    </ur-box-container>

    <!-- 바텀시트 : 수금체크명세 유의사항  -->
    <mo-bottom-sheet ref="nsbottomsheet" class="ns-bottom-sheet closebtn">
      <template v-slot:title>수금체크명세 유의사항</template>
      <div @click="fn_CloseBottomSheet" class="ns-btn-close"></div> 
      <div class="content-area verify-agree">
        <div class="text-gray-box bgcolor-2 bd-radius-0 pal0">
          <ul class="terms-list-area crTy-bk7 fs14rem">
            <li>자원은 월초1회(마감이후) 구성되며, 입금상태는 매일 업데이트 됩니다.(단, 자동이체는 이체일+1일) </li>
            <li>수금자원실적현황 데이터가 산출된 후, 리스트에 반영됩니다.</li>
            <li>월대체 발생 계약은 계약상태 및 유지년월이 익일 반영되어 실효나 연체로 보일 수도 있습니다. 반드시 계약사항을 확인 후 안내바랍니다.</li>
            <li>외화보험의 경우 보험료는 전월 말일 기준으로 원화계산된 보험료 입니다.</li>
            <li>문자수신이 불가한 고객은 선택할 수 없습니다.</li>
            <li>지로/카드납의 경우 유지반영이 지연 될 수 있으니 고객 안내시 계약 기본사항 확인 후 안내 바랍니다.</li>
          </ul>
        </div>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CloseBottomSheet" name="확인">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    <!-- 바텀시트 : 수금체크명세 유의사항  -->

    <!-- 계약 로딩 표시 -->
    <div class="msp loading--dim msp-in-page" v-show="isReading">
      <div class="preloader-bubbling">
        <span id="bubbling_1"></span>
        <span id="bubbling_2"></span>
        <span id="bubbling_3"></span>
      </div>
      <div class="loading-text-box">
        <span class="title">{{loadingMsg}}</span>
        <span class="txt" v-html="loadingSubMsg" />
      </div>
    </div>

  </ur-page-container>
</template>


<script>

import Msg         from '@/systems/webkit/msg/msg'
import pmConstants from '@/config/constants/pmConstants'
import MSPPM712P   from '@/ui/pm/MSPPM712P'               // 수금체크명세 상세조건 
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Screen from '~systems/mixin/screen'

export default {
  name: 'MSPPM711M', 
  screenId: 'MSPPM711M', 
  mixins: [Screen],
  components: {    
    MSPPM712P,
  },//componets,

  props:{},//props

  data () {
    return {

      title: '수금체크명세',

      lv_inputParam: {},                                                  // 서비스호출 파라미터 

      /**
       * 사용자 정보 변수
       */
      userInfo: {},                                                       // 조직 및 컨설턴트 정보
      isMngr: false,                                                      // 지점장 여부
      pOrgDataMSPPM711M: {},                                              // 최근 조회조건 데이터

      /**
       * 화면 변수
       */
      isNoData: false,        
      isLoading: false,
      isFirst: true,                                                      // 최초접근여부
      isReading: false,
      loadingMsg: '고객 정보 조회중',
      loadingSubMsg: '2~3분 정도 소요될 수 있습니다.',

      /**
       * 체크박스 변수
       */
      isCheckAll: false,                                                  // 전체체크 여부 
      isDisabledCheck: false,                                             // 체크박스 비활성화 여부 (타 컨설턴트 고객 호출 하면 체크박스 비활성화) 
      totalCount: 0,                                                      // 전체 고객수 
      selectedItems: [],                                                  // 선택된 고객 리스트 

      /**
       * 조회 변수
       */ 
      clmnyChkSpcfSVO: [],                                                // 수금체크명세 조회 데이터 
      searchedCustNm: '',                                                 // 고객명
      gContScCd: pmConstants.CLMNY_CHK_SPCF_CUST_SC_CD[1].value,          // 고객구분(=단체계약구분코드) : 개인
      cDResrcScCd: pmConstants.CLMNY_CHK_SPCF_RESRC_SC_CD[3].value,       // 자원구분(=수금상세자원구분코드) : 해당.연체
      thmmRctmScd: pmConstants.CLMNY_CHK_SPCF_RCTM_STAT_CD[4].value,      // 입금상태(=당월입금구분코드) : 미입금&연체입금
      salesP: pmConstants.CLMNY_CHK_SPCF_ENT_PRDT_CD[1].value,            // 가입상품(=보험상품구분코드) : 보장전체
      ulScCd: pmConstants.CLMNY_CHK_SPCF_UL_SC_CD[0].value,               // UL구분코드(=UL구분) : 전체
      ukepNts: pmConstants.CLMNY_CHK_SPCF_UKEP_MNTH[0].value,             // 유지개월(=유지개월) : 전체 
      prmPadMthCd: pmConstants.CLMNY_CHK_SPCF_PAD_MTH_CD[0].value,        // 납입방법(=보험료납입방법코드) : 전체 

      searchedItems: {},                                                  // 검색결과 데이터 

      /**
       * 제어 변수 
       */ 
      isSearched: false,                                                  // 조회이력 저장 변수 
      isCleared: true,                                                    // 조회조건 초기화 여부 
      
      /** 
       * 팝업 연동
       */
      popup712: {},                                                       // MSPPM712P 수금체크명세 상세조건 

    }
  },//data

  created () {
     
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickBackBtn)     // backKey Event 등록
    window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')                       // post 호출 전 Progress 타입 설정

    this.userInfo = this.getStore('userInfo').getters.getUserInfo                   // 사용자 정보

    /**
     * 지점장 여부를 확인한다.
     * 해당 사용자는 본인이 아닌 FC번호로 조회가능하다.
     */
    if(this.$cmUtil.isSrchOrgCombo() === 'Y') this.isMngr = true

    this.fn_Submit()

  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  beforeDestroy () {

    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickBackBtn)      // backKey Event 해제
    window.vue.getStore('progress').dispatch('FULL')                                  // post 호출 전 Progress 타입 설정

  },//beforeDestroy

  watch: {},//watch

  computed: {
    
    /**
     * 선택된 데이터 개수를 구한다.
     */
    checkedCount(){
      return this.clmnyChkSpcfSVO.filter(item => item.checked).length
    },
    
    /**
     * 선택된 데이터를 추출한다. 
     */
    checkedList(){
      return this.clmnyChkSpcfSVO.filter(item => item.checked)
    }
  },//computed 

  methods: {

    /**
     * @description 초기 조회조건으로 초기화 한다.
     */
    fn_ClearCond(){
      
      this.pOrgDataMSPPM711M = {}                                                
      this.searchedCustNm    = ''                                                
      this.gContScCd         = pmConstants.CLMNY_CHK_SPCF_CUST_SC_CD[1].value,   
      this.cDResrcScCd       = pmConstants.CLMNY_CHK_SPCF_RESRC_SC_CD[3].value,  
      this.thmmRctmScd       = pmConstants.CLMNY_CHK_SPCF_RCTM_STAT_CD[4].value, 
      this.salesP            = pmConstants.CLMNY_CHK_SPCF_ENT_PRDT_CD[1].value, 
      this.ulScCd            = pmConstants.CLMNY_CHK_SPCF_UL_SC_CD[0].value,     
      this.ukepNts           = pmConstants.CLMNY_CHK_SPCF_UKEP_MNTH[0].value,    
      this.prmPadMthCd       = pmConstants.CLMNY_CHK_SPCF_PAD_MTH_CD[0].value,   
      this.isSearched        = false  
      this.searchedItems     = {}     

    },
    
    /**
     * @description 체크된 값이 있는지 확인 후, 뒤로가기 실행 함수를 호출한다.
     */
    fn_ClickBackBtn(){
      
      if (this.checkedCount > 0) {
        this.fn_confirm()
        return 
      } 
      
      this.fn_BackToPrevious()

    },

    /**
     * @description - (현재조건값 === 초기조건값) 뒤로가기를 한다.
     *              - (현재조건값 !== 초기조건값) 조건 초기화 후 조회한다.
     */
    fn_BackToPrevious(){

      if(this.isSearched && !this.isCleared){  
        this.fn_ClearCond()
        this.fn_Submit()
      }else{
        // 메뉴로 진입했으면 홈화면으로 이동한다.
        if (this.$route.query?._isMenuItem) {
          this.$router.push({name: 'MSPBC002M'})
          return
        }
        this.$router.go(-1)
      }

    },

    /**
     * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
     * @notice      상세조회 팝업에서 조직 항목 변경(지점, 지역단, 컨설턴트) 후, 정상적인 조회 가능하다.
     *              조직 항목 변경(사업부) 후, 정상적인 조회가 안된다면 VO부터 수정해야한다.
     *              VO에 clmHofOrgNo 필드 존재하지 않는다.
     */
    fn_Submit(){
      
      // 초기화  
      this.totalCount = 0  
      this.isNoData   = false
      this.clmnyChkSpcfSVO.splice(0)

      // 체크된 고객 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init)

      // 조직 및 컨설턴트 데이터 세팅 
      this.lv_inputParam.clmClnsltId  = this.userInfo.userId        // 컨설턴트번호 : cnsltNo
      this.lv_inputParam.clmDofOrgId  = this.userInfo.onpstDofNo    // 소속지역단조직번호 : dofOrgNo
      this.lv_inputParam.clmFofOrgId  = this.userInfo.onpstFofOrgNo // 소속지점조직번호 : fofOrgNo
      
      // this.lv_inputParam.clmHofOrgNo  = this.userInfo.onpstHofOrgNo // 소속사업부조직번호 : HofOrgNo

      if(this.pOrgDataMSPPM711M.cnslt !== undefined && this.isMngr === true) {
        // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
        if(this.userInfo.userId !== this.pOrgDataMSPPM711M.cnslt.key){
          this.isDisabledCheck = true
        } else {
          this.isDisabledCheck = false
        }
        // 선택된 조직 및 컨설턴트 데이터 세팅 
        this.lv_inputParam.clmClnsltId = this.pOrgDataMSPPM711M.cnslt.key  
        this.lv_inputParam.clmDofOrgId = this.pOrgDataMSPPM711M.dofOrg.key 
        this.lv_inputParam.clmFofOrgId = this.pOrgDataMSPPM711M.fofOrg.key 

        // this.lv_inputParam.clmHofOrgNo  = this.pOrgDataMSPPM711M.hofOrg.key // 소속사업부조직번호 : HofOrgNo
      
      }else{
        this.isDisabledCheck = false
      }

      // 조회조건 세팅
      this.lv_inputParam.gContScCd       = this.gContScCd     
      this.lv_inputParam.cDResrcScCd     = this.cDResrcScCd    
      this.lv_inputParam.thmmRctmScd     = this.thmmRctmScd    
      this.lv_inputParam.salesP          = this.salesP        
      this.lv_inputParam.ulScCd          = this.ulScCd        
      this.lv_inputParam.ukepNts         = this.ukepNts        
      this.lv_inputParam.prmPadMthCd     = this.prmPadMthCd    

      window.vue.getStore('progress').dispatch('SKEL_LIST_CHK') // post 호출 전 Progress 타입 설정.

      // 서비스 호출
      this.fn_GetService()

    },

   /**
     * @description 수금체크명세 목록 조회 서비스 호출한다.
     * @callback    fn_HandleCallback
     */
    fn_GetService() {

      const trnstId =  'txTSSPM40S6' 
      const auth = 'S'

      let pParams = this.lv_inputParam

      this.isLoading = true

      return this.post(this, pParams, trnstId, auth)
        .then(this.fn_HandleCallback)
        .catch(function (error) { window.vue.error(error) })

    },

   /**
    * @description 콜백 데이터를 처리한다.
    * @param       {Object} response 콜백 데이터
    */
    fn_HandleCallback(response){

      let confirm = this.getStore('confirm')

      if(response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003'){
          
          let responseList = response.body.pMClmnyChkSpcfVO   // 수금체크명세 리스트 
          
          if (responseList !== null) {

            // 수금체크명세 조회 데이터 가공 
            responseList.forEach((item, index) => {

              // 고객명이 존재할 경우
              if(!this.$bizUtil.isEmpty(this.searchedCustNm.trim())){
                let target = new String(this.searchedCustNm.trim())
                let isEng  = /[A-Za-z]/

                // 영어여부 및 uppercase
                if(isEng.test(target)) target = target.toUpperCase()

                // 고객명을 포함하지 않으면 return
                if(!responseList[index].policyHoderNm.includes(target)) return

              }

              item.markUpContNo   = item.applnrTt.substr(item.applnrTt.length - 5, 5)       
              item.lstPadDt       = this.$commonUtil.dateDashFormat(item.lstPadDt)          
              item.premaftertaxAm = this.$commonUtil.numberWithCommas(item.premaftertaxAm) 

              this.$set(responseList[index], 'checked', false)
              this.$set(responseList[index], 'idx', index)
              this.$set(responseList[index], 'chnlCustId', '')
              this.$set(responseList[index], 'contNo', item.applnrTt)
              this.$set(responseList[index], 'custNm', item.policyHoderNm)

              this.clmnyChkSpcfSVO.push(responseList[index])

            })

            // 조회데이터 총 개수 
            this.totalCount = this.clmnyChkSpcfSVO.length

          }

          // 수금체크명세 유의사항 열기 (최초접근일 때만 노출)
          if(this.isFirst) {
            this.$refs.nsbottomsheet.open()
            this.isFirst = false 
          }

        }else{
          confirm.dispatch('ADD', response.msgComm.msgDesc)
        }

        this.isLoading = false  

        // 데이터 유무 
        if (this.clmnyChkSpcfSVO.length === 0) {
          this.isNoData = true    
        } else {
          this.isNoData = false  
        }

    },

    /**
     * @description 조회목록을 부분선택 한다.
     */
    fn_ClickCheckPartial(){

      for (let i in this.checkedList) {
        this.checkedList[i].custId    = this.checkedList[i].contrId
        this.checkedList[i].contNo    = this.checkedList[i].applnrTtEnc
        this.checkedList[i].contNoEnc = this.checkedList[i].applnrTtEnc
      }

      this.checkedList.busnCd = 'PM'
      
      // 공통 시트를 가리고 사용자 시트를 보여준다.
      this.$cmUtil.setSelectedCustList(this, this.checkedList)

    },

    /**
     * @description 체크박스 이외의 영역 클릭했을 때, 체크 활성화 및 비활성화 한다.
     * @param       {Number} index 리스트 데이터의 index
     */
    fn_OnClickListItem(index) {

      if(this.isDisabledCheck) return 
      
      this.$refs[`chk${index}`].onClick()
    },


    /**
     * @description 수금명세체크 상세조건 팝업을 제어한다.
     */
    fn_OpenMSPPM712P(){

      this.popup712 = this.$bottomModal.open(MSPPM712P,{
        properties: {
          pPage: this.$options.screenId,
          pIsMngr: this.isMngr,                     // 지점장 여부 
          pOrgData: this.pOrgDataMSPPM711M,         // 최근 조직 및 컨설턴트 
          pSelectedItems: this.searchedItems,       // 조회조건 
          pSearchedCustNm: this.searchedCustNm,     // 고객명 
          pGContScCd: this.gContScCd,               // 고객구분
          pCDResrcScCd: this.cDResrcScCd,           // 자원구분
          pThmmRctmScd: this.thmmRctmScd,           // 입금상태                     
          pSalesP: this.salesP,                     // 가입상품
          pUlScCd: this.ulScCd,                     // UL구분
          pUkepNts: this.ukepNts,                   // 유지개월
          pPrmPadMthCd: this.prmPadMthCd,           // 납입방법
        },

        listeners: {
          searchPopup: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(this.popup712)

            this.searchedCustNm    = pData.rtnData.searchedCustNm  
            this.gContScCd         = pData.rtnData.gContScCd       
            this.cDResrcScCd       = pData.rtnData.cDResrcScCd     
            this.thmmRctmScd       = pData.rtnData.thmmRctmScd    
            this.salesP            = pData.rtnData.salesP          
            this.ulScCd            = pData.rtnData.ulScCd          
            this.ukepNts           = pData.rtnData.ukepNts         
            this.prmPadMthCd       = pData.rtnData.prmPadMthCd     
            this.pOrgDataMSPPM711M = pData.rtnData.pOrgData       
            this.searchedItems     = pData.rtnData.selectedItems  
            this.isCleared         = pData.rtnData.isCleared      
            this.isSearched        = true                        

            // 수금체크명세 조회
            this.fn_Submit()

          }
        }
      })

    },

    
    /**
     * @description 계약자명을 클릭할 때 nameUI를 호출한다.
     * @param       {Object}  item 선택된 수금체크명세 데이터 
     */
    async fn_OpenCustInfoBs(item){

      let prcContNo  = '',
          cnsltNo    = '',  
          nmUiObject = {}

      // 계약번호 복호화 
      if(!this.$bizUtil.isEmpty(item.applnrTtEnc)){
        prcContNo = await this.$commonUtil.getDecryptCall(this, [item.applnrTtEnc]) 
        prcContNo = prcContNo[0]
      }

      if(this.pOrgDataMSPPM711M.cnslt === undefined || this.pOrgDataMSPPM711M.cnslt === null || this.pOrgDataMSPPM711M.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgDataMSPPM711M.cnslt.key
      }

      nmUiObject = {
        chnlCustId: item.chnlCustId,
        custNm: item.custNm,
        cnsltNo: cnsltNo,
        contNo : prcContNo,
        parentId: this.$options.screenId
      }

      this.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject, function (response){
        return
      })

    },

    /**
     * @description 보험계약기본사항(MSPPM) 화면으로 이동한다.
     *              @type viewId     수신화면, callerId   송신화면, chnlCustId  채널아이디
     *                    cnsltNo    (필수값) 컨설턴트번호, contNo     (필수값) 계약번호
     * @param       {String} contNo     계약번호
     * @param       {String} chnlCustId 채널아이디 
     */
    async fn_MoveMSPPM931M(contNo, chnlCustId) {

      let prcContNo   = '',
          cnsltNo     = '',
          contInfoObj = {}

      // 계약번호 복호화 
      if(!this.$bizUtil.isEmpty(contNo)) {
        prcContNo = await this.$commonUtil.getDecryptCall(this, [contNo]) 
        prcContNo = prcContNo[0]
      }

      if(this.pOrgDataMSPPM711M.cnslt === undefined || this.pOrgDataMSPPM711M.cnslt === null || this.pOrgDataMSPPM711M.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgDataMSPPM711M.cnslt.key
      }

      contInfoObj = {
        viewId: null,                         
        callerId: this.$options.screenId,     
        chnlCustId: chnlCustId,               
        cnsltNo: cnsltNo,               
        contNo: prcContNo,                       
      }

      this.$router.push({name: 'MSPPM931M', params: contInfoObj})

    },

   /**
    * @description BottomSheet(해지환급금 유의사항) 팝업을 닫는다.
    */
    fn_CloseBottomSheet(){
      this.$refs.nsbottomsheet.close()
    },

    /**
     * @description 로딩 메시지를 설정한다.
     * @param       pShowFlag Progress 화면 설정 Flag
     */
    fn_SetProgressMsgPop(pShowFlag) {
      this.isReading = pShowFlag
    },

    /**
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     */
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              this.fn_BackToPrevious()
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },

    /**
     * @description 이체일자와 납입방법 getting
     * @param {Object} item 고객정보
     */
    fn_GetPadInfo(item){
      let rtnValue = ''

      if (this.$bizUtil.isEmpty(item.atrHopDdCdTt)) {
        rtnValue = item.prmPadMthCdTt
      } else {
        rtnValue = item.atrHopDdCdTt + '(' + item.prmPadMthCdTt + ', ' + item.banka + ')'
      }
      return rtnValue
    },
    
 },// methods

}//export default


</script>